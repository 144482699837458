import React from "react";
import Select from "react-select";

const SingleSelect = ({ userList, onChange, selectedValue }) => {
  const options = userList.map((a) => {
    return { label: `${a.name} ${a.surname}`, value: a.id };
  });

  const selectComponentStyles = {
    option: (styles) => ({
      ...styles,
      minWidth: "100%",
      whiteSpace: "nowrap",
      cursor: "pointer",
    }),
    menu: (styles) => ({
      ...styles,
      width: "100%",
      zIndex: 0,
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      borderRadius: "10px",
    }),
  };
  return (
    <Select
      options={options}
      styles={selectComponentStyles}
      maxMenuHeight={150}
      menuPlacement="bottom"
      menuPortalTarget={document.body}
      onChange={onChange}
      placeholder=""
      value={selectedValue}
    />
  );
};

export default SingleSelect;
