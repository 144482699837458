import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RespectPage from "./pages/respectPage/RespectPage";
import CouragePage from "./pages/couragePage/CouragePage";
import TenacityPage from "./pages/tenacityPage/TenacityPage";
import CarePage from "./pages/carePage/CarePage";
import ThankYouPage from "./pages/thankYouPage/ThankYouPage";
import Loader from "./components/loader/Loader";
import Header from "./components/header/Header";
import LandingPage from "./pages/landingPage/LandingPage";
import RulesPage from "./pages/rulesPage/RulesPage";
import ForbiddenPage from "./pages/forbiddenPage/ForbiddenPage";
import VotePage from "./pages/votePage/VotePage";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    index: false,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: "rules",
        element: <RulesPage />,
      },
      {
        path: "voting",
        element: (
          <>
            <VotePage />
          </>
        ),
      },
      {
        path: "thankYou",
        element: (
          <>
            <ThankYouPage />
          </>
        ),
      },
      {
        path: "forbidden",
        element: (
          <>
            <ForbiddenPage />
          </>
        ),
      },
    ],
  },
]);

root.render(<RouterProvider router={router} />);
