import React, { useEffect, useState } from "react";
import "./LandingPage.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import bannerMobile from "../../images/landingPage/landing-page-banner-mobile.jpg";
import banner from "../../images/landingPage/landing-page-banner.jpg";
const LandingPage = () => {
  const [searchParams] = useSearchParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("code")) {
      localStorage.setItem("code", searchParams.get("code"));
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth < 750);
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="landing-page-container">
      <div className="landing-page-banner">
        <img src={isMobile ? bannerMobile : banner} alt="banner" />
      </div>
      <div className="landing-page-bottom-container">
        <div className="landing-page-text">Izbor za kolege godine 2024.</div>
        <button
          className="landing-page-button"
          onClick={() => navigate("/rules")}
        >
          Pravila
        </button>
        <div className="landing-page-footer">
          <div className="landing-page-badge"></div>
          <div className="landing-page-logo"></div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
