import React from "react";

const TenacityIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9426 2.98906C16.1625 2.76294 16.461 2.63606 16.7721 2.63606H21.4602L23.914 0.459772C24.6095 -0.157021 25.641 -0.152797 26.3315 0.469803L30.3728 4.11302C30.7474 4.45054 30.9729 4.92989 30.9977 5.44128C31.0225 5.95266 30.8448 6.45261 30.5047 6.82691L27.5834 10.0436V20.0064C27.5834 20.5181 27.3353 20.9963 26.9219 21.2813L19.0069 26.7396C18.7608 26.9094 18.4709 27 18.1744 27H12.8256C12.5291 27 12.2392 26.9094 11.9931 26.7396L4.07815 21.2813C3.66468 20.9963 3.41656 20.5181 3.41656 20.0064V10.0436L0.495287 6.82691C0.155228 6.45261 -0.0225381 5.95266 0.00229167 5.44128C0.0271214 4.92989 0.252568 4.45054 0.627173 4.11302L4.66848 0.469803C5.35904 -0.152797 6.39055 -0.157021 7.08596 0.459772L9.53979 2.63606H14.0097C14.3208 2.63606 14.6191 2.76294 14.8391 2.98906L15.3908 3.55606L15.9426 2.98906ZM20.5858 14.1538C21.2497 15.1266 22.5497 15.3985 23.5339 14.753C24.518 14.1077 24.8261 12.7816 24.2473 11.753L20.5858 14.1538ZM17.8885 14.6654H13.2398C12.8659 14.6654 12.5251 14.8859 12.3635 15.2322L9.05435 22.3252C8.85553 22.7513 8.97464 23.2623 9.33971 23.5497L12.4017 25.96C12.5714 26.0936 12.779 26.1659 12.9926 26.1659H18.1355C18.3491 26.1659 18.5568 26.0936 18.7264 25.96L21.7884 23.5497C22.1535 23.2623 22.2726 22.7513 22.0738 22.3252L18.7646 15.2322C18.603 14.8859 18.2622 14.6654 17.8885 14.6654ZM15.5641 17.6955L15.3077 17.9929L14.2438 17.5438L14.5097 16.6067L12.5528 16.5286L13.2178 15.3767H15.5356L15.5641 15.4045L15.5925 15.3767H17.9103L18.5753 16.5286L16.6184 16.6067L16.8843 17.5438L15.8206 17.9929L15.5641 17.6955ZM10.4142 14.1538L6.75274 11.753C6.17392 12.7816 6.48195 14.1077 7.46614 14.753C8.45034 15.3985 9.7503 15.1266 10.4142 14.1538Z"
        fill={fill}
      />
    </svg>
  );
};

export default TenacityIcon;
