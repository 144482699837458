import React from "react";
import "./Loader.scss";

const Loader = ({ id }) => {
  return (
    <div id={id} className="loaderWrapper">
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
