import { Outlet, useSearchParams } from "react-router-dom";
import "./App.css";
import { RecoilRoot } from "recoil";
import { useEffect } from "react";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("code")) {
      const param = searchParams.get("code");
      localStorage.setItem("code", param);
      setSearchParams(undefined);
    }
  }, []);

  return (
    <>
      <RecoilRoot>
        <Outlet />
      </RecoilRoot>
    </>
  );
}

export default App;
