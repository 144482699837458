const onRequest = async (config) => {
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error, axiosInstance) => {
  return Promise.reject(error);
};

export function ApiHandler(axiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, (error) =>
    onResponseError(error, axiosInstance)
  );
  return axiosInstance;
}
