import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { tabState, voteState } from "../../utils/globalStore/RecoilStore";
import RespectPage from "../respectPage/RespectPage";
import CouragePage from "../couragePage/CouragePage";
import TenacityPage from "../tenacityPage/TenacityPage";
import CarePage from "../carePage/CarePage";
import { useRecoilValue, useSetRecoilState } from "recoil";

const VotePage = () => {
  const tab = useRecoilValue(tabState);
  const setVotes = useSetRecoilState(voteState);

  const showPage = () => {
    if (tab.respect) {
      return <RespectPage />;
    } else if (tab.courage) {
      return <CouragePage />;
    } else if (tab.tenacity) {
      return <TenacityPage />;
    } else if (tab.care) {
      return <CarePage />;
    }
  };

  const [renderPage, setRenderPage] = useState(showPage());

  useEffect(() => {
    setVotes((prev) => ({ ...prev, code: localStorage.getItem("code") }));
    setRenderPage(showPage());
  }, [tab]);

  return (
    <>
      <Header />
      {renderPage}
    </>
  );
};

export default VotePage;
