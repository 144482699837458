import React, { useCallback, useState } from "react";
import "./RulesPage.scss";
import { useNavigate } from "react-router-dom";
import { userState, voteState } from "../../utils/globalStore/RecoilStore";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Loader from "../../components/loader/Loader";
import { validateCode } from "../../utils/functions/ValidateCodeFunction";

const RulesPage = () => {
  const [loading, setLoading] = useState(false);
  const vote = useRecoilValue(voteState);
  const setVotes = useSetRecoilState(voteState);
  const setUsers = useSetRecoilState(userState);

  const navigate = useNavigate();
  const startApplication = useCallback(async () => {
    setLoading(true);
    const userCode = localStorage.getItem("code");
    await validateCode(userCode, vote, setVotes, setUsers, navigate);
  });

  return (
    <>
      {loading && <Loader />}
      <div className="rules-page-container">
        <div className="rules-page-content">
          <div className="rules-page-header">Pravila glasanja</div>
          <div className="rules-page-text">
            Izbor za kolege godine se sprovodi u 4 kategorije koje predstavljaju
            TIAC vrednosti.
            <br />
            <br />
            <ul className="rules-page-bullet">
              <li>
                RESPECT - Kolega koji ima najviše poštovanja prema drugima.
              </li>
              <li>COURAGE - Kolega koji je hrabar i donosi smele odluke.</li>
              <li>
                TENACITY - Kolega koji je uporan u pronalaženju rešenja kako bi
                postigao ciljeve.
              </li>
              <li>CARE - Kolega koji najviše brine o drugima.</li>
            </ul>
            <br />
            Na svakoj od narednih stranica izaberite kolegu koji po vašem
            mišljenju najviše odražava datu vrednost. Obrazloženje vašeg izbora
            je poželjno, ali nije neophodno. 😊 Dozvoljeno je da glasate za
            istog kolegu u više kategorija.
            <br />
            <br />
            <i>
              Vaše izbore možete da menjate sve dok ih kod poslednje vrednosti
              ne prosledite.
            </i>
          </div>
          <button className="rules-page-button" onClick={startApplication}>
            Započni
          </button>
        </div>
        <div className="rules-page-logo"></div>
      </div>
    </>
  );
};

export default RulesPage;
