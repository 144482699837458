import { http } from "../helpers/Http";

class VoteServiceProvider {
  async vote(userVote) {
    try {
      const response = await http.post(`/vote`, userVote);
      return response.data;
    } catch (error) {}
  }
}

const VoteService = new VoteServiceProvider();
export default VoteService;
