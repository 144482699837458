import { atom } from "recoil";

export const tabState = atom({
  key: "tab",
  default: { respect: true, courage: false, tenacity: false, care: false },
});

export const voteState = atom({
  key: "vote",
  default: {
    code: "",
    votes: [
      { id: 0, comment: "" },
      { id: 0, comment: "" },
      { id: 0, comment: "" },
      { id: 0, comment: "" },
    ],
  },
});

export const userSelectState = atom({
  key: "userSelect",
  default: [
    { value: 0, label: "" },
    { value: 0, label: "" },
    { value: 0, label: "" },
    { value: 0, label: "" },
  ],
});

export const userState = atom({
  key: "users",
  default: [],
});
