import React from "react";
import "./Header.scss";
import RespectIcon from "../icons/RespectIcon";
import CourageIcon from "../icons/CourageIcon";
import TenacityIcon from "../icons/TenacityIcon";
import CareIcon from "../icons/CareIcon";
import { switchTab } from "../../utils/functions/SwitchTabFunction";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { tabState, voteState } from "../../utils/globalStore/RecoilStore";

const Header = () => {
  const tabs = useRecoilValue(tabState);
  const setTabs = useSetRecoilState(tabState);
  const vote = useRecoilValue(voteState);

  const tabHandler = (tab) => {
    let allowSwitch = false;
    switch (tab) {
      case "respect":
        allowSwitch = true;
        break;
      case "courage":
        if (vote.votes[0].id !== 0) {
          allowSwitch = true;
        }
        break;
      case "tenacity":
        if (vote.votes[1].id !== 0) {
          allowSwitch = true;
        }
        break;
      case "care":
        if (vote.votes[2].id !== 0) {
          allowSwitch = true;
        }
        break;
      default:
        break;
    }
    if (allowSwitch) {
      switchTab(tab, setTabs);
    }
  };

  return (
    <div className="header-container">
      <div className="header-icons">
        <div
          id="respect"
          className="icon-box"
          onClick={() => tabHandler("respect")}
        >
          <RespectIcon
            fill={tabs.respect ? "#1FA0BB" : "#2C2C2C"}
            width={34}
            height={34}
          />
        </div>
        <div
          id="courage"
          className="icon-box"
          onClick={() => tabHandler("courage")}
        >
          <CourageIcon
            fill={tabs.courage ? "#BB4321" : "#2C2C2C"}
            width={42}
            height={26}
          />
        </div>
        <div
          id="tenacity"
          className="icon-box"
          onClick={() => tabHandler("tenacity")}
        >
          <TenacityIcon
            fill={tabs.tenacity ? "#9D3B3E" : "#2C2C2C"}
            width={31}
            height={27}
          />
        </div>
        <div id="care" className="icon-box" onClick={() => tabHandler("care")}>
          <CareIcon
            fill={tabs.care ? "#5AB146" : "#2C2C2C"}
            width={32}
            height={33}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
