import UserService from "../../services/executions/UserService";

export const validateCode = async (
  userCode,
  vote,
  setVotes,
  setUsers,
  navigate
) => {
  if (userCode || userCode?.trim() !== "") {
    const validate = await UserService.validateCode(userCode);
    if (validate?.isValid) {
      if (validate.canVote) {
        const userVote = { ...vote };
        userVote.code = userCode;
        setVotes(userVote);
        const users = await UserService.getNominees(userCode);
        setUsers(users);
        navigate("/voting");
      } else {
        navigate("/thankYou");
      }
    } else {
      navigate("/forbidden");
    }
  } else {
    navigate("/forbidden");
  }
};
