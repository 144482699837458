import axios from "axios";
import { ApiHandler } from "./Interceptors/ApiHandler";

export const http = ApiHandler(
  axios.create({
    baseURL: "https://kolegagodine-api.tiacgroup.net",
    headers: {
      "Content-Type": "application/json",
    },
  })
);
