export const switchTab = (tabName, setFunction) => {
  switch (tabName) {
    case "respect":
      setFunction({
        respect: true,
        courage: false,
        tenacity: false,
        care: false,
      });
      break;
    case "courage":
      setFunction({
        respect: false,
        courage: true,
        tenacity: false,
        care: false,
      });

      break;
    case "tenacity":
      setFunction({
        respect: false,
        courage: false,
        tenacity: true,
        care: false,
      });

      break;
    case "care":
      setFunction({
        respect: false,
        courage: false,
        tenacity: false,
        care: true,
      });

      break;

    default:
      setFunction({
        respect: true,
        courage: false,
        tenacity: false,
        care: false,
      });

      break;
  }
};
