import React, { useCallback, useEffect, useState } from "react";
import SingleSelect from "../../components/singleSelect/SingleSelect";
import "./CarePage.scss";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  tabState,
  userSelectState,
  userState,
  voteState,
} from "../../utils/globalStore/RecoilStore";
import VoteService from "../../services/executions/VoteService";
import Loader from "../../components/loader/Loader";
import { validatePage } from "../../utils/functions/ValidatePageFunction";
import { switchTab } from "../../utils/functions/SwitchTabFunction";

const CarePage = () => {
  const setTabs = useSetRecoilState(tabState);
  const navigate = useNavigate();
  const userSelect = useRecoilValue(userSelectState);
  const setUserSelect = useSetRecoilState(userSelectState);
  const users = useRecoilValue(userState);
  const setUsers = useSetRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(userSelect[3]);
  const setVotes = useSetRecoilState(voteState);
  const vote = useRecoilValue(voteState);
  const [submitValid, setSubmitValid] = useState(vote.votes[3].id !== 0);

  const onSelectHandler = (value) => {
    let votes = JSON.parse(JSON.stringify([...vote.votes]));
    let userSelects = [...userSelect];
    setSelectedUser(value);
    votes[3].id = value.value;
    userSelects[3] = value;
    setUserSelect(userSelects);
    setVotes((prev) => ({ ...prev, votes: votes }));
  };

  const onCommentHandler = (value) => {
    let votes = JSON.parse(JSON.stringify([...vote.votes]));
    votes[3].comment = value?.trim();
    setVotes((prev) => ({ ...prev, votes: votes }));
  };

  const voteHandler = async () => {
    VoteService.vote(vote).then((res) => {
      if (res) {
        navigate("/thankyou");
      }
    });
  };

  const checkIfValid = useCallback(async () => {
    await validatePage(setLoading, navigate, setUsers);
  });

  useEffect(() => {
    checkIfValid();
    setTabs({
      respect: false,
      courage: false,
      tenacity: false,
      care: true,
    });
  }, []);

  return (
    <>
      {loading && <Loader id="submit" />}
      <div className="care-background">
        <div className="care-animal"></div>
        <div className="care-content">
          <div className="care-text-container">
            <div className="care-header">CARE</div>
            <div className="care-text">... for employees</div>
            <div className="care-text">... for mankind</div>
            <div className="care-text">... for surroundings</div>
          </div>
          <div className="input-container">
            <div className="single-select">
              <SingleSelect
                userList={users}
                selectedValue={selectedUser}
                onChange={onSelectHandler}
              />
            </div>
            <div>
              <textarea
                className="comment-field"
                defaultValue={vote.votes[3].comment}
                cols="10"
                rows="5"
                onChange={(e) => onCommentHandler(e.target.value)}
                placeholder="Ostavi komentar..."
                maxLength={200}
              ></textarea>
            </div>
            <div className="arrow-container-care">
              <div
                className="care-back-arrow-box"
                onClick={() => switchTab("tenacity", setTabs)}
              >
                <div className="care-back-arrow"></div>
              </div>
            </div>
          </div>
          <div className="submit-button-container">
            {vote.votes[3].id !== 0 && (
              <button className="submit-button" onClick={voteHandler}>
                <div>Prosledi</div>
                <div className="submit-arrow"></div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarePage;
