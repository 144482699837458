import UserService from "../../services/executions/UserService";

export const validatePage = async (setLoading, navigate, setUsers) => {
  const userCode = localStorage.getItem("code");
  setLoading(true);
  if (userCode) {
    const validate = await UserService.validateCode(userCode);
    if (validate.isValid) {
      if (validate.canVote) {
        const users = await UserService.getNominees(userCode);
        setUsers(users);
        setLoading(false);
      } else {
        navigate("/thankYou", { replace: true });
      }
    } else {
      navigate("/forbidden", { replace: true });
    }
  } else {
    navigate("/forbidden", { replace: true });
  }
};
