import { http } from "../helpers/Http";

class UserServiceProvider {
  async getNominees(code) {
    try {
      const response = await http.get(`/user/nominees?code=${code}`);
      return response.data;
    } catch (error) {}
  }
  async validateCode(code) {
    try {
      const response = await http.get(`/user?code=${code}`);
      return response.data;
    } catch (error) {}
  }
}

const UserService = new UserServiceProvider();
export default UserService;
