import React from "react";
import "./ThankYouPage.scss";

const ThankYouPage = () => {
  return (
    <div className="thank-you-background">
      <div className="thank-you-logo"></div>
      <div className="thank-you-content">
        <div className="thank-you-header">
          Vaš glas je uspešno zabeležen. <br />
          <br />
          Hvala što ste deo TIAC tima!
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
