import React, { useCallback, useEffect, useState } from "react";
import "./RespectPage.scss";
import SingleSelect from "../../components/singleSelect/SingleSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  tabState,
  userSelectState,
  userState,
  voteState,
} from "../../utils/globalStore/RecoilStore";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Loader from "../../components/loader/Loader";
import { validatePage } from "../../utils/functions/ValidatePageFunction";
import { switchTab } from "../../utils/functions/SwitchTabFunction";

const RespectPage = () => {
  const setTabs = useSetRecoilState(tabState);
  const navigate = useNavigate();
  const userSelect = useRecoilValue(userSelectState);
  const setUserSelect = useSetRecoilState(userSelectState);
  const users = useRecoilValue(userState);
  const setUsers = useSetRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(userSelect[0]);
  const setVotes = useSetRecoilState(voteState);
  const vote = useRecoilValue(voteState);

  const onSelectHandler = (value) => {
    let votes = JSON.parse(JSON.stringify([...vote.votes]));
    setSelectedUser(value);
    let userSelects = [...userSelect];
    votes[0].id = value.value;
    userSelects[0] = value;
    setUserSelect(userSelects);
    setVotes((prev) => ({ ...prev, votes: votes }));
  };

  const onCommentHandler = (value) => {
    let votes = JSON.parse(JSON.stringify([...vote.votes]));
    votes[0].comment = value?.trim();
    setVotes((prev) => ({ ...prev, votes: votes }));
  };

  const checkIfValid = useCallback(async () => {
    await validatePage(setLoading, navigate, setUsers);
  });

  useEffect(() => {
    checkIfValid();
    setTabs({
      respect: true,
      courage: false,
      tenacity: false,
      care: false,
    });
  }, []);

  return (
    <>
      {loading && <Loader id="submit" />}
      <div className="respect-background">
        <div className="respect-animal"></div>
        <div className="respect-content">
          <div className="respect-text-container">
            <div className="respect-header">RESPECT</div>
            <div className="respect-text">... for people</div>
            <div className="respect-text">... for clients</div>
            <div className="respect-text">... for our team</div>
          </div>
          <div className="input-container">
            <div className="single-select">
              <SingleSelect
                userList={users}
                selectedValue={selectedUser}
                onChange={onSelectHandler}
              />
            </div>
            <div>
              <textarea
                className="comment-field"
                defaultValue={vote.votes[0].comment}
                cols="10"
                rows="5"
                onChange={(e) => onCommentHandler(e.target.value)}
                placeholder="Ostavi komentar..."
                maxLength={200}
              ></textarea>
            </div>
            <div className="arrow-container-respect">
              {vote.votes[0].id !== 0 && (
                <div
                  className="respect-forward-arrow-box"
                  onClick={() => switchTab("courage", setTabs)}
                >
                  <div className="respect-forward-arrow"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RespectPage;
